import { useCallback, useEffect, useState } from 'react'
import { useFetch } from '../../../../../hooks/useFetch'
import { Flex } from '../../../../layout/Flex'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Pagination } from './Pagination'
import { ModalBox } from '../../../../base/ModelBox'
import { EditSparepart } from './EditSparepart'
import { DeleteSparepart } from './DeleteSparepart'
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess'
import { useAuth } from '../../../../../utils/auth'
import { QrCodeViwer } from '../../../../shared/QrCode/QrViwer'


export default function SparepartTable({ itemsData, pageCount, handlePageChange, refetch }: any) {
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [qrCode, setQrCode] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>()
    const auth = useAuth();
    const editSparePartCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SparePartsInventory && acc.permission.includes(AccessPermissions.Edit));

    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setIsEdit(false)
        setIsDelete(false)
        refetch()
    }, [refetch])

    let extractedData = []
    if (itemsData) {
        extractedData = itemsData?.data?.map((item) => {
            return {
                ID: item?._id,
                SKU: item?.sku,
                Name: item?.name,
                Quantity: item?.quantity,
                Price: item?.price,
                Model: item?.models?.map((mdl) => mdl?.modelName).join(', '),
                Currency: item?.currency,
            }
        })
    }

    const columns = ['SKU', 'Name', 'Quantity', 'Price', 'Model', 'Currency']

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item)
        if (optionValue === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (optionValue === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        }else if (optionValue === 'Print') {
            console.log('Print', item.SKU)
            console.log(item)
            setQrCode(true)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditSparepart item={selectedModel} onClose={closeModal} />
        } else if (isDelete) {
            return <DeleteSparepart item={selectedModel} onClose={closeModal} />
        }
    }, [isEdit, isDelete, selectedModel, closeModal])

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        options={['Print','Edit', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={editSparePartCheck ? true : false}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            {qrCode && <QrCodeViwer onClose={() => setQrCode(false)} item={selectedModel} type="Spare part "/>}
            {itemsData?.pagination && <Pagination
                pageCount={pageCount}
                onPageChange={handlePageChange}
            />}
            {isModalOpen && renderModalComponent()
            }
        </>
    )
}
