import React, { useEffect, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { AddInventoryContent } from './AddInventoryModel'
import { BulkInventoryContent } from './BulkInventoryModel'
import { ExportModel } from './ExportModel'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess'
import { useAuth } from '../../../../../utils/auth'

export default function Head({ refetch, activeData }: any) {
    const [modalContent, setModalContent] = useState(null)
    const auth = useAuth();
    console.log({activeData});
    const addInventoryCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.InHouseInventory && acc.permission.includes(AccessPermissions.Add));
    const handleExport = () => {
        setModalContent(addInventoryCheck  && <ExportModel data={activeData} onClose={() => { setModalContent(null), refetch() }} />)
    }

    const handleAddInventory = () => {
        setModalContent(
            addInventoryCheck && <AddInventoryContent onClose={() => { setModalContent(null), refetch() }} />
        )
    }

    const handleBulkInventory = () => {
        setModalContent(
            <BulkInventoryContent onClose={() => { setModalContent(null), refetch() }} />
        )
    }
    return (
        <Flex className="pb-4 pt-2 ">
            <RowAlignCenterSpaced className="gap-x-5  ">
                <Text colorV="blue" variant="head" weightV="bold">
                    In-house inventory
                </Text>
                <RowAlignCenter className="gap-x-3">
                    {addInventoryCheck && <Button onClick={handleExport} backgroundColor="green">
                        <Text colorV="white" variant="title" weightV="normal">
                            Export
                        </Text>
                    </Button>}
                    {addInventoryCheck && <Button onClick={handleAddInventory} backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Add inventory item
                        </Text>
                    </Button>}
                    {addInventoryCheck && <Button
                        onClick={handleBulkInventory}
                        backgroundColor="blue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Bulk inventory upload
                        </Text>
                    </Button>}
                </RowAlignCenter>
            </RowAlignCenterSpaced>
            {modalContent}
        </Flex>
    )
}
