import React, { useCallback, useEffect, useState } from 'react'
import Head from './Head'
import MyRmaTable from './component/MyRmaTable'
import { useFetch } from '../../../../hooks/useFetch'
import { Filter } from './component/Filter'
import { Row } from '../../../layout/Row'
import { useAuth } from '../../../../utils/auth'
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess'
const baseUrl = process.env.REACT_APP_baseUrl

export const MyRma = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [filterData, setFilterData] = useState(null)
    
    const { getRequest, data } = useFetch(`${baseUrl}/rma/items-my-rma`)
    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )
    const handleSelectedItemsChange = (items) => {
        setSelectedItems(items);
    };

    useEffect(() => {
        getRequest()
        getCodes()
    }, [getCodes, getRequest])

    const refetch = useCallback(() => {
        getRequest()
    }, [getRequest])

    const handleFilterChange = useCallback((data) => {
        setFilterData(data)
    }, [])
    return (
        <>
            <Row className='gap-x-3 pb-5 pt-2'>
                <Head refetch={refetch} selectedItems={selectedItems} />
                <Filter itemsData={data} onDataChange={handleFilterChange} refetch={refetch} />
            </Row>
            <MyRmaTable
                onSelectedItemsChange={handleSelectedItemsChange}
                data={filterData?.length > 0 ? filterData : data} refetch={refetch} codesData={codesData} />
        </>
    )
}
