import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../base/Button';
import { ModalBox } from '../../../base/ModelBox';
import { Column } from '../../../layout/Col';
import { Flex } from '../../../layout/Flex';
import { Text } from '../../../base/Text';
import 'react-calendar/dist/Calendar.css';
import { usePost } from '../../../../hooks/usePost';
import { addNotification } from '../../../shared/Notification/Notification';

const baseUrl = process.env.REACT_APP_baseUrl;

export const ScriptCalendar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest } = usePost();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const urlItem = `${baseUrl}/stocktaking-imei/run-script`;
    try {
      const response = await sendRequest(urlItem, { scriptDate: new Date() });
      setIsLoading(false);
      onClose();
      addNotification({
        message: 'Script has been run successfully',
        type: 'success',
      });
      toast.success(response?.data, { hideProgressBar: true });
    } catch (error) {
      setIsLoading(false);
      onClose();
      toast.error(`Error: ${error.response?.data?.message}`, {
        hideProgressBar: true,
      });
    }
  }, [onClose, sendRequest]);

  return (
    <ModalBox
      heading="Run the script"
      onClose={onClose}
      top="50%"
      height="auto"
      width="400px"
    >
      <Column className="mt-3 gap-y-4 h-[80px] overflow-auto items-center">
        <Flex className="gap-x-4 mt-4">
          <Button backgroundColor="blue" onClick={handleSubmit}>
            <Text colorV="white" variant="title" weightV="normal">
              {isLoading ? "Running script" : "Run the script"}
            </Text>
          </Button>
        </Flex>
      </Column>
    </ModalBox>
  );
};
