import React, { useCallback, useState } from 'react'
import { Button } from '../../../base/Button'
import { Text } from '../../../base/Text'
import { Flex } from '../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { RowAlignCenter } from '../../../layout/RowAlignCenter'
import { DropdownActions } from '../../../shared/DropdownAction/DropdownAction'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../../../../utils/auth'
import { useNavigate } from 'react-router-dom'
import { addNotification } from '../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export default function Head({ selectedItems, refetch }: any) {
    const auth = useAuth()
    const navigate = useNavigate()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany
    const options = ['Export', 'Send', 'Declined', 'Merge in batch', 'Unmerge the batch'];
    const handleOptionClick = (option) => {
        if (option === 'Export') {
            handleCSV();
        } else if (option === 'Send') {
            handleSend();
        } else if (option === 'Merge in batch') { // Corrected to lowercase 'b'
            handleBatchNumber();
        } else if (option === 'Declined') { // Corrected to 'Declined'
            handleDecline();
        }
        else if (option === 'Unmerge the batch') { // Corrected to 'Declined'
            handleUnMerge();
        }
    };
    console.log({ selectedItems });
    const handleCSV = useCallback(async () => {
        if (selectedItems.length === 0) {
            toast.error('Error: No items selected', {
                hideProgressBar: true,
            });
            return;
        }
        const csvArray = []
        if (selectedItems?.length > 0) {
            selectedItems?.forEach((element) => {
                csvArray.push({
                    supplier: element?.Supplier,
                    'RMA Batch': element?.['RMA Batch#'] || '',
                    ' Invoice Number': element?.invoiceNumber || '',
                    imei: element?.IMEI || '',
                    event: element?.Event || '',
                    'Days left': element?.['Days left'],
                    Issue: element?.Issue || "",
                    "Issue Description": element?.["Issue Description"]
                })
            })

            let csv = ' '
            for (let row = 0; row < csvArray?.length; row++) {
                const keysAmount = Object.keys(csvArray[row]).length
                let keysCounter = 0
                if (row === 0) {
                    for (const key in csvArray[row]) {
                        csv +=
                            key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }
                keysCounter = 0
                for (const key in csvArray[row]) {
                    csv +=
                        csvArray[row][key] +
                        (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
                keysCounter = 0
            }
            const data = new Blob([csv], { type: 'text/csv' })
            saveCsv(data, 'myRMA.csv')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [selectedItems])

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }

    const handleSend = useCallback(async () => {
        if (selectedItems.length === 0) {
            toast.error('Error: No items selected', {
                hideProgressBar: true,
            });
            return;
        }
        // const itemIds = selectedItems.map(item => item.id);
        const item = `${baseUrl}/rma/supplier-rma`
        await axios
            .put(
                item,
                {
                    ids: selectedItems,
                },
                {
                    headers: {
                        Authorization: token as string,
                        Company: company._id
                    },
                }
            )
            .then((response) => {
                console.log(response)
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'RMA has been sent successfully',
                    type: 'success',
                })
                refetch()
            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [company._id, refetch, selectedItems, token])

    const handleBatchNumber = useCallback(async () => {
        if (selectedItems.length === 0) {
            toast.error('Error: No items selected', {
                hideProgressBar: true,
            });
            return;
        }
        // const itemIds = selectedItems.map(item => item.id);

        const item = `${baseUrl}/item/batch-number`
        await axios
            .put(
                item,
                {
                    ids: selectedItems,
                },
                {
                    headers: {
                        Authorization: token as string,
                        Company: company._id
                    },
                }
            )
            .then((response) => {
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'Batch number has been added successfully',
                    type: 'success',
                })
                refetch()

            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [company._id, refetch, selectedItems, token])

    const handleUnMerge = useCallback(async () => {
        if (selectedItems.length === 0) {
            toast.error('Error: No items selected', {
                hideProgressBar: true,
            });
            return;
        }
        // const itemIds = selectedItems.map(item => item.id);

        const item = `${baseUrl}/item/un-batch-number`
        await axios
            .put(
                item,
                {
                    ids: selectedItems,
                },
                {
                    headers: {
                        Authorization: token as string,
                        Company: company._id
                    },
                }
            )
            .then((response) => {
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'Batch number has been removed successfully',
                    type: 'success',
                })
                refetch()

            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [company._id, refetch, selectedItems, token])

    const handleDecline = useCallback(async () => {
        if (selectedItems.length === 0) {
            toast.error('Error: No items selected', {
                hideProgressBar: true,
            });
            return;
        }
        // const itemIds = selectedItems.map(item => item.id);

        const item = `${baseUrl}/item/decline`
        await axios
            .put(
                item,
                {
                    ids: selectedItems,
                },
                {
                    headers: {
                        Authorization: token as string,
                        Company: company._id
                    },
                }
            )
            .then((response) => {
                console.log(response);
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'RMA has been declined successfully',
                    type: 'success',
                })
                refetch()
            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [company._id, refetch, selectedItems, token])

    const viewArchive = useCallback(() => {
        navigate("archive")
    }, [navigate])
    return (
        <Flex className=" w-full">
            <RowAlignCenterSpaced className="gap-x-4 w-full">
                <Text
                    colorV="blue"
                    variant="head"
                    weightV="bold"
                >
                    Manage my RMA
                </Text>
                <RowAlignCenter className="gap-x-4">
                    <DropdownActions options={options} onActionClick={handleOptionClick} />
                    <Button
                        backgroundColor="yellow"
                        onClick={() => viewArchive()}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            My Archive
                        </Text>
                    </Button>
                </RowAlignCenter>
            </RowAlignCenterSpaced>
            {/* {isAttention && <Attention onClose={onClose} items={selectedItems} />} */}
        </Flex>
    )
}
