import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from '../../../../layout/Flex';
import { Table } from '../../../../shared/Table';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import moment from 'moment';
import { PrintLabel } from '../../../Inventory/Inhouse/components/PrintLabel';
import { generatePDF } from '../../../../shared/GenerateLabel';
import { useFetch } from '../../../../../hooks/useFetch';
import JsBarcode from 'jsbarcode';
import { EventNameEnum } from '../../../../../constant/SortingLogicEvent';
const baseUrl = process.env.REACT_APP_baseUrl;

export default function RmaTable({ data, codesData, onSelectedItemsChange }: any) {
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [allSelected, setAllSelected] = useState(false);
    const barCode = useRef<HTMLDivElement>(null);
    console.log({ selectedItems });

    const extractedData = data?.map((item) => {
        const createdAt = moment(item.createdAt);
        const currentDate = moment();
        const differenceInDays = currentDate.diff(createdAt, 'days');
        const totalDays = Math.max(parseInt(item.rmaSupplier) - differenceInDays, 0); // Ensure totalDays is not below 0
        const issues = item?.codes?.map((code) => {
            const matchingCode = codesData?.find((data) => data.label === code);
            return matchingCode ? matchingCode.description : code;
        });
        return {
            item: item,
            id: item?._id,
            Supplier: item?.supplier?.companyName,
            'RMA Batch#': item?.batchNumber,
            'Invoice number': item?.invoiceNumber,
            IMEI: item?.imei,
            Status: item?.sortingEvent.name,
            'Days left': totalDays,
            Issue: item.codes.toString(),
            'Issue Description': issues.join(', '),
        };
    }).filter((item) => item.Status !== EventNameEnum.My_RMA_Sent_To_Supplier);

    const handleCheckboxChange = useCallback((e, selectedItem) => {
        const itemId = selectedItem.item?._id;
        if (e.target.checked) {
            if (!selectedItems.includes(itemId)) {
                setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
            }
        } else {
            setSelectedItems((prevSelectedItems) =>
                prevSelectedItems.filter((id) => id !== itemId)
            );
        }
    }, [selectedItems]);

    useEffect(() => {
        onSelectedItemsChange(selectedItems);
        const allSelected = extractedData?.length > 0 && extractedData.every(item => selectedItems.includes(item.item._id));
        setAllSelected(allSelected);
    }, [onSelectedItemsChange, selectedItems, extractedData]);

    const handleAllChange = useCallback((e, allItems) => {
        console.log({ allItems });
        const allSelectedIds = allItems.map((item) => item.item._id);

        if (e.target.checked) {
            setSelectedItems(allSelectedIds); // Select all items
        } else {
            setSelectedItems([]); // Deselect all items
        }
    }, []);

    const columns = [
        'Supplier',
        'RMA Batch#',
        'Invoice number',
        'IMEI',
        'Status',
        'Days left',
        'Issue',
        'Issue Description',
    ];

    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );

    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handleDropdownChange = useCallback((selectedOption, item) => {
        JsBarcode('#barcode', `${item?.item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 1,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        });
        generatePDF(item, labelsData, barCode)
    }, [labelsData]);

    return (
        <>
            <Flex className="w-full ">
                <PrimaryCard className="w-full">
                    <Table
                        showAll={true}
                        options={['Print label']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        showCheckboxes={true}
                        selectedRows={selectedItems}
                        onCheckboxChange={handleCheckboxChange}
                        handleDropdownChange={handleDropdownChange}
                        onAllCheckboxChange={handleAllChange}
                        allSelected={allSelected}
                    />
                </PrimaryCard>
            </Flex>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </>
    );
}
