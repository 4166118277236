import { useCallback, useState } from 'react'
import { Button } from '../../../../../base/Button'
import { Text } from '../../../../../base/Text'
import { Column } from '../../../../../layout/Col'
import { Row } from '../../../../../layout/Row'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { ReportName } from './ReportName'
import { TextField } from '../../../../../base/Textfield'
import { TextBackground } from '../../../../../base/TextBackground'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import * as XLSX from 'xlsx';

import moment from 'moment'
import { usePost } from '../../../../../../hooks/usePost'
import { GiClawHammer } from 'react-icons/gi'
const baseUrl = process.env.REACT_APP_baseUrl

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
export const ReportsData = () => {
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [selectedData, setSelectedData] = useState(null);
    const [type, setType] = useState("pdf")
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<any>()
    const currentDate = moment().format('DDMMYYYY');

    const { sendRequest } = usePost()
    const handleDropdownChange = (data) => {
        setSelectedData(data);
    };

    const handleStartDate = useCallback((newValue: any) => {
        setStartDate(newValue)
    }, [])

    const handleEndDate = useCallback((newValue: any) => {
        setEndDate(newValue)
    }, [])
    const handleDownloadOptionChange = (e) => {
        const selectedOption = e.target.value;
        setType(selectedOption)
    }

    const handleInventoryPDF = useCallback(async (itemsData) => {
        // Group items by IMEI
        // @ts-ignore
        const result: any = Object.groupBy(itemsData, (item) => item?.imei);
        console.log({ result });
    
        // Prepare the body for the PDF table
        const body = [];
    
        // Check if result has any entries
        if (result && Object.keys(result).length > 0) {
            for (const [imei, groupItems] of Object.entries(result)) {
                // Iterate through each group and add rows to the body
                // @ts-ignore
                groupItems?.forEach((itm) => {
                    console.log({ itm });
                    const row = [
                        itm?.itemId?.model?.modelName || '',
                        itm?.itemId?.storage || '',
                        itm?.itemId?.vat || '',
                        itm?.itemId?.imei || '',
                        itm?.itemId?.codes?.toString() || "",
                        itm?.event?.name || '',
                        itm?.itemId?.acceptance || "",
                        moment(itm?.createdAt).format('DD.MM.YYYY') || '',
                    ];
                    body.push(row);
                });
            }
    
            // Add headers to the body
            body.unshift([
                'Model',
                'Storage',
                'VAT',
                'IMEI',
                'Codes',
                'Status',
                'Status',
                'Date',
            ]);
    
            // Define the PDF document structure
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };
    
            // Generate and download the PDF
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download(`${currentDate}-inventory-report`);
    
            // Reset the end date
            setEndDate(null);
        } else {
            console.log("No items found for generating the PDF.");
        }
    }, [currentDate]);
    


    const handleBatchPDF = useCallback(async (itemsData) => {
        const values = Object.values(itemsData).flat();
    
        if (values && values.length > 0) {
            const body = values.map((itm: any) => {
                const sparePartPrice = itm?.itemId?.repair?.finishAt ?
                    itm.itemId?.spareParts.reduce((total, sparePart) => total + (sparePart.price * sparePart.pieces), 0)
                    : 0;
    
                return [
                    itm?.itemId?.supplier?.companyName || '',
                    itm?.itemId?.model?.modelName || '',
                    itm?.itemId?.storage || '',
                    itm?.itemId?.imei || '',
                    itm?.itemId?.purchasePrice || '',
                    itm?.itemId?.salePrice || '',
                    itm?.itemId?.acceptance,
                    moment(itm?.createdAt).format('DD.MM.YYYY HH:mm') || '',
                    itm?.event?.name || '',
                    itm?.itemId?.grade?.grade || '',
                    itm?.itemId?.spareParts?.map((sparePart) => sparePart?.sparePart?.name).join(',') || '',
                    sparePartPrice
                ];
            });
    
            // Add headers to the body
            body.unshift([
                'Supplier',
                'Model',
                'Storage',
                'IMEI',
                "Purchase price",
                "Sale price",
                "Status",
                'Timestamp',
                'Status',
                "Grade",
                "Spare parts",
                "Spare part price"
            ]);
    
            // Define the PDF document structure
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };
    
            // Generate and download the PDF
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download(`${currentDate}.batch.report.${values[0]?.itemId?.invoiceNumber}.pdf`);
    
            // Reset the end date
            setEndDate(null);
        } else {
            console.log("No items found for generating the PDF.");
        }
    }, [currentDate]);

    const handleSparepartPDF = useCallback(async (itemsData: any) => {
        if (itemsData?.length > 0) {
            const body = itemsData?.map((itm: any) => {
                return [
                    itm?.sku,
                    itm?.name,
                    itm?.models?.map((mdl) => mdl?.modelName).join(', '),
                    itm?.quantity,
                    itm?.price,
                ]
            })
            body.unshift(['SKU', 'Name', 'Model', 'Quantity', 'Price'])
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            }

            // @ts-ignore
            pdfMake.createPdf(docDefinition).download(`${currentDate}-spare-part-report`)
            setEndDate(null)
            setEndDate(null)
        }
    }, [currentDate])

    const getRepairTimePdf = useCallback((reports) => {
        const repairTime = reports?.repair
        const startTime = moment(repairTime?.startAt).format(
            'DD/MM/YYYY HH:mm:ss'
        )
        const finishTime = moment(repairTime?.finishAt).format(
            'DD/MM/YYYY HH:mm:ss'
        )
        const ms = moment(finishTime, 'DD/MM/YYYY HH:mm:ss:SSS').diff(
            moment(startTime, 'DD/MM/YYYY HH:mm:ss:SSS')
        )
        const diffDuration = moment.duration(ms)
        if (repairTime?.startAt && repairTime?.finishAt) {
            return (
                diffDuration.days() +
                'd ' +
                diffDuration.hours() +
                'h ' +
                diffDuration.minutes() +
                'm ' +
                diffDuration.seconds() +
                's'
            )
        } else if (repairTime?.startAt && repairTime?.cancelAt) {
            return 'Repair cancelled'
        } else {
            return 'Repair Not Started'
        }
    }, [])
    const handleUserPDF = useCallback(async (itemsData: any) => {
        if (itemsData?.length > 0) {
            console.log({ itemsData });
            const body = itemsData?.map((itm: any) => {
                return [
                    itm?.model?.modelName || "",
                    itm?.storage || "",
                    itm?.vat || "",
                    itm?.imei || "",
                    getRepairTimePdf(itm),
                    moment(itm?.repair?.finishAt).format("DD MMM YYYY"),
                    itm?.spareParts?.map(({ sparePart }) => sparePart?.name) || "",
                    itm?.codes?.toString() || "",
                    itm?.codesAfterFix?.toString() || "",
                    itm?.repair?.repairPoints || 0,
                ]
            })
            body.unshift([
                "Model",
                "Storage",
                "Vat",
                "IMEI",
                "Repair time",
                "Finish At",
                "Spare Parts",
                "Codes before fix",
                // "Codes after fix",
                "Repair points",
            ])
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        text: `${itemsData[0]?.repair?.finishedBy
                            ?.firstName} ${itemsData[0]?.repair?.finishedBy
                                ?.lastName} ${itemsData[0]?.repair?.finishedBy
                                    ?.email} Repairs report from ${moment(startDate).format(
                                        '"DD MMM YYYY"'
                                    )} to ${moment(endDate).format('"DD MMM YYYY"')}`,
                        bold: true,
                        alignment: "center",
                    },
                    {
                        margin: [0, 15, 0, 8],
                        bold: true,
                        text: `${itemsData[0]?.repair?.finishedBy
                            ?.firstName} ${itemsData[0]?.repair?.finishedBy
                                ?.lastName} (${itemsData[0]?.repair?.finishedBy
                                    ?.email
                            }) repaired ${itemsData.length
                            } devices with total repair points `,
                    },
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            }
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download(`${currentDate}-inventory-report`)
        }
    }, [currentDate, endDate, getRepairTimePdf, startDate]);

    const saveBlob = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
        document.body.removeChild(a);
    };
    
    const handleInventoryCSV = useCallback(async (itemsData) => {
        console.log({ itemsData });
    
        if (itemsData?.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(itemsData.map(element => ({
                date: moment(element?.createdAt)?.format('DD/MM/YYYY') || '',
                model: element?.itemId?.model?.modelName || '',
                storage: element?.itemId?.storage || '',
                VAT: element?.itemId?.vat || '',
                imei: element?.itemId?.imei || '',
                codes: element?.itemId?.codes?.map((cd) => cd || ' ').join(' ') || '',
                event: element?.event?.name || '',
                grade: element?.itemId?.grade?.grade || '',
                status: element?.itemId?.acceptance || ''
            })));
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventory Report');
    
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
            saveBlob(data, `${currentDate}-inventory-report.xlsx`);
        } else {
            toast.error('Error: No data found', { hideProgressBar: true });
        }
    }, [currentDate]);
    
    const handleUserCSV = useCallback(async (itemsData) => {
        if (itemsData?.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(itemsData.map(itm => ({
                "Model": itm?.model?.modelName || "",
                "Storage": itm?.storage || "",
                "Vat": itm?.vat || "",
                "IMEI": itm?.imei || "",
                "Repair time": getRepairTimePdf(itm),
                "Finish At": moment(itm?.repair?.finishAt).format("DD MMM YYYY"),
                "Spare Parts": itm?.spareParts.map(({ sparePart }) => sparePart?.name).join(", ") || "",
                "Codes before fix": itm?.codes?.join(" ") || "",
                "Codes after fix": itm?.codesAfterFix?.join(" ") || "",
                "Repair points": itm?.repair?.repairPoints || 0
            })));
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');
    
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
            saveBlob(data, `${currentDate}-performance-report.xlsx`);
        } else {
            toast.error("Error: No data found", { hideProgressBar: true });
        }
    }, [currentDate, getRepairTimePdf]);
    
    const handleBatchCSV = useCallback(async (itemsData) => {
        if (itemsData?.length > 0) {
            const csvArray = [
                ['Supplier', 'Timestamp', 'Model', 'Storage', 'IMEI', 'Purchase price', 'Sale price', "Status", 'Event', 'Grade', 'Spare parts', 'Spare parts price']
            ];
    
            itemsData.forEach((itm) => {
                const sparePartPrice = itm?.itemId?.repair?.finishAt ?
                    itm.itemId?.spareParts.reduce((total, sparePart) => total + (sparePart.price * sparePart.pieces), 0)
                    : 0;
    
                csvArray.push([
                    itm?.itemId?.supplier?.companyName || '', // Include supplier information
                    moment(itm?.createdAt).format('DD.MM.YYYY') || '',
                    itm?.itemId?.model?.modelName || '',
                    itm?.itemId?.storage || '',
                    itm?.itemId?.imei || '',
                    itm?.itemId?.purchasePrice || '',
                    itm?.itemId?.salePrice || '',
                    itm?.itemId?.status || '',
                    itm?.event?.name || '',
                    itm?.itemId?.grade?.grade || '',
                    itm?.itemId?.spareParts?.map((sparePart) => sparePart?.sparePart?.name).join(' ') || '',
                    sparePartPrice
                ]);
            });
    
            const worksheet = XLSX.utils.aoa_to_sheet(csvArray);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Batch Report');
    
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
            saveBlob(data, `${currentDate}-batch-report-${itemsData[0].itemId?.invoiceNumber}.xlsx`);
        } else {
            toast.error('Error: No data found', { hideProgressBar: true });
        }
    }, [currentDate]);
    
    const handleSparePartCSV = useCallback(async (itemsData) => {
        if (itemsData?.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(itemsData.map(itm => ({
                SKU: itm?.sku || '',
                Name: itm?.name || '',
                Model: itm?.models?.map((mdl) => mdl?.modelName).join(' ') || '',
                Quantity: itm?.quantity || '',
                Price: itm?.price || ''
            })));
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Spare Part Report');
    
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
            saveBlob(data, `${currentDate}-spare-part-report.xlsx`);
        } else {
            toast.error('Error: No data found', { hideProgressBar: true });
        }
    }, [currentDate]);
    
    const handleDownloadPDF = useCallback(async () => {
        setIsLoading(true);
        if (!startDate) {
            toast.error('Please, select start date');
            setIsLoading(false);
            return;
        } else if (
            selectedData?.type === 'user' && // Only validate end date for 'user' type
            !endDate
        ) {
            toast.error('Please, select end date');
            setIsLoading(false);
            return;
        } else if (!selectedData?.type) {
            toast.error('Please, select report type');
            setIsLoading(false);
            return;
        }

        let apiUrl = '';
        switch (selectedData?.type) {
            case 'user':
                apiUrl = `${baseUrl}/report/user-report`;
                break;
            case 'inventory':
                apiUrl = `${baseUrl}/report/inventory-report`;
                break;
            case 'sparePart':
                apiUrl = `${baseUrl}/report/spare-part-report`;
                break;
            case 'batch':
                apiUrl = `${baseUrl}/report/batch-report`;
                break;
            default:
                apiUrl = '';
                break;
        }

        if (apiUrl) {
            let requestData: any = {
                value: selectedData?.value,
            };

            if (selectedData?.type === 'user') {
                requestData = {
                    ...requestData,
                    startDate,
                    endDate,
                };
            } else {
                requestData = {
                    ...requestData,
                    date: startDate,
                };
            }
            await sendRequest(
                apiUrl,
                {
                    requestData,
                },
            )
                .then((response) => {
                    if (response.data.items.length === 0) {
                        toast.error("No data found", {
                            hideProgressBar: true,
                        });
                    }
                    if (response?.data?.type === "inventory") {
                        handleInventoryPDF(response?.data.items)
                    } else if (response?.data?.type === "batch") {
                        handleBatchPDF(response?.data?.items)
                    }
                    else if (response?.data?.type === "sparePart") {
                        handleSparepartPDF(response?.data?.items)
                    } else if (response?.data?.type === "user") {
                        handleUserPDF(response?.data?.items)
                    }
                    toast.success(response?.data?.message, { hideProgressBar: true });
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(`Error: ${error?.response?.data?.message}`, {
                        hideProgressBar: true,
                    });
                }).finally(() => {
                    setIsLoading(false); // Set loading to false when download completes (whether successful or not)
                });
        } else {
            alert('Unsupported report type');
            setIsLoading(false);
        }
    }, [endDate, handleBatchPDF, handleInventoryPDF, handleSparepartPDF, handleUserPDF, selectedData?.type, selectedData?.value, sendRequest, startDate])

    const handleDownloadCSV = useCallback(async () => {
        setIsLoading(true);
        if (!startDate) {
            toast.error('Please, select start date');
            setIsLoading(false);
            return;
        } else if (
            selectedData?.type === 'user' && // Only validate end date for 'user' type
            !endDate
        ) {
            toast.error('Please, select end date');
            setIsLoading(false);
            return;
        } else if (!selectedData?.type) {
            toast.error('Please, select report type');
            setIsLoading(false);
            return;
        }

        let apiUrl = '';
        switch (selectedData?.type) {
            case 'user':
                apiUrl = `${baseUrl}/report/user-report`;
                break;
            case 'inventory':
                apiUrl = `${baseUrl}/report/inventory-report`;
                break;
            case 'sparePart':
                apiUrl = `${baseUrl}/report/spare-part-report`;
                break;
            case 'batch':
                apiUrl = `${baseUrl}/report/batch-report`;
                break;
            default:
                apiUrl = '';
                break;
        }

        if (apiUrl) {
            let requestData: any = {
                value: selectedData?.value,
            };
            if (selectedData?.type === 'user') {
                requestData = {
                    ...requestData,
                    startDate,
                    endDate,
                };
            } else {
                requestData = {
                    ...requestData,
                    date: startDate,
                };
            }
            await sendRequest(
                apiUrl,
                {
                    requestData,
                },
            )
                .then((response) => {
                    if (response.data.items.length === 0) {
                        toast.error("No data found", {
                            hideProgressBar: true,
                        });
                    }
                    if (response?.data?.type === "inventory") {
                        handleInventoryCSV(response?.data.items)
                    } else if (response?.data?.type === "batch") {
                        handleBatchCSV(response?.data?.items)
                    }
                    else if (response?.data?.type === "sparePart") {
                        handleSparePartCSV(response?.data?.items)
                    } else if (response?.data?.type === "user") {
                        handleUserCSV(response?.data?.items)
                    }
                    toast.success(response?.data?.message, { hideProgressBar: true });
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(`Error: ${error?.response?.data?.message}`, {
                        hideProgressBar: true,
                    });
                }).finally(() => {
                    setIsLoading(false); // Set loading to false when download completes (whether successful or not)
                });
        } else {
            alert('Unsupported report type');
            setIsLoading(false);
        }
    }, [endDate, handleBatchCSV, handleInventoryCSV, handleSparePartCSV, handleUserCSV, selectedData?.type, selectedData?.value, sendRequest, startDate])

    const handleDownload = useCallback(() => {
        if (!selectedData) {
            toast.error('Please select a report before downloading.', {
                hideProgressBar: true,
            });
            return;
        }

        if (type === 'pdf') {
            handleDownloadPDF();
        } else if (type === 'csv') {
            handleDownloadCSV();
        }
    }, [handleDownloadCSV, handleDownloadPDF, selectedData, type]);
    return (
        <>
            <Row className="gap-x-3 mt-5 w-full">
                <ReportName onDropdownChange={handleDropdownChange} />
                <PrimaryCard variant='white' className='w-full items-center p-5'>
                    <Column className='gap-y-7 items-center'>
                        <RowAlignCenterSpaced className="flex-grow-1 flex items-center justify-center">
                            {selectedData?.type === 'user' ? (
                                <>
                                    <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                                        <Text
                                            colorV="blue"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            Select Start date
                                        </Text>
                                    </TextBackground>
                                    <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                                        <Text
                                            colorV="blue"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            Select end date
                                        </Text>
                                    </TextBackground>
                                </>
                            ) : (
                                <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="normal"
                                    >
                                        Select date
                                    </Text>
                                </TextBackground>
                            )}
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="flex-grow-1 flex items-center justify-center">
                            {selectedData?.type === 'user' ? (
                                <>
                                    <TextField
                                        type="date"
                                        value={startDate}
                                        defaultValue={startDate}
                                        onChange={handleStartDate}
                                        backgroundColor='white'
                                        className="w-56 bg-white"
                                    />
                                    <TextField
                                        type="date"
                                        value={endDate}
                                        defaultValue={endDate}
                                        onChange={handleEndDate}
                                        backgroundColor='white'
                                        className="w-56"
                                    />
                                </>
                            ) : (
                                <TextField
                                    type="date"
                                    backgroundColor='white'
                                    value={startDate}
                                    defaultValue={startDate}
                                    onChange={handleStartDate}
                                    className="w-56 bg-white"
                                />
                            )}
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className='gap-x-3'>
                            <Button onClick={handleDownload} className='w-56' backgroundColor="purple">
                                <Text colorV="white" variant="title" weightV="normal">
                                    {isLoading ? 'Downloading...' : 'Download'}
                                </Text>
                            </Button>
                            <div className="">
                                <select
                                    onChange={handleDownloadOptionChange}
                                    id="pdf"
                                    defaultValue="pdf"
                                    style={{ width: '14rem' }}
                                    className="border hover:cursor-pointer  rounded-lg py-2 px-1 border-none bg-white"
                                >
                                    <option value="pdf">PDF</option>
                                    <option value="csv">XLSX</option>
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                    </Column>
                </PrimaryCard>
            </Row>
        </>
    )
}
