import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { useFetch } from '../../../../../hooks/useFetch'
import moment from 'moment'
import { EditInventory } from './EditInventoryModel'
import { DeleteInventory } from './DeleteInventoryModel'
import { PrintLabel } from './PrintLabel'
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess'
import { useAuth } from '../../../../../utils/auth'
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import JsBarcode from 'jsbarcode';
import { generatePDF } from '../../../../shared/GenerateLabel'
import { EventNameEnum } from '../../../../../constant/SortingLogicEvent'
const baseUrl = process.env.REACT_APP_baseUrl;

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

export default function FilterTable({ itemsData, refetch }: any) {
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>()
    const auth = useAuth();
    const barCode = useRef<HTMLDivElement>(null);

    const editInventoryCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.InHouseInventory && acc.permission.includes(AccessPermissions.Edit));
    let extractedData = []
    if (itemsData) {
        extractedData = itemsData?.map((item) => {
            if (item.sortingEvent?.name === 'warranty_repair' || item.sortingEvent.name === "sold") {
                return null; // Skip this item
            }
            return {
                Id: item?._id,
                IMEI: item?.imei,
                Date: moment(item?.createdAt).format('DD.MM.YYYY'),
                Model: item?.model?.modelName,
                Storage: item?.storage,
                VAT: item?.vat,
                Status: item?.sortingEvent?.name,
                Code: item?.codes?.toString(),
                // Code: item?.codesAfterFix.length > 0 ? item?.codesAfterFix.toString() : item?.codes.toString(),
                item: item
            };
        }).filter(Boolean); // Remove null values from the array
    }

    const columns = ['IMEI', 'Date', 'Model', 'Storage', 'VAT', 'Status', 'Code']

    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setIsEdit(false)
        setIsDelete(false)
        refetch()
    }, [refetch])
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item);
        if (optionValue === 'Edit') {
            setIsEdit(true);
            setIsDelete(false);
        } else if (optionValue === 'Delete') {
            setIsDelete(true);
            setIsEdit(false);
        } else if (optionValue === 'Print') {
            setIsEdit(false);
            setIsDelete(false);
            handlePrintlabelPDF(item);
        }
        setIsModalOpen(true);
    }

    const handlePrintlabelPDF = useCallback(async (item) => {
        console.log(item);
        JsBarcode('#barcode', `${item?.item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 1,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        });
        generatePDF(item, labelsData, barCode)
    }, [labelsData]);

    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditInventory item={selectedModel} onClose={closeModal} />;
        } else if (isDelete) {
            return <DeleteInventory item={selectedModel} onClose={closeModal} />;
        }
    }, [isEdit, isDelete, selectedModel, closeModal]);

    return (
        <>
            <Flex className="mt-5 ">
                <PrimaryCard className='w-full'>
                    <Table
                        options={editInventoryCheck ? ['Print', 'Edit', 'Delete'] : ['Print', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
            {isModalOpen && (renderModalComponent()
            )}
        </>
    )
}
