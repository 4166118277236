import React, { useEffect, useState } from 'react'
import { Flex } from '../../../../../layout/Flex'
import { Table } from '../../../../../shared/Table'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { useFetch } from '../../../../../../hooks/useFetch'
import moment from 'moment'

const baseUrl = process.env.REACT_APP_baseUrl

export default function SparepartTable() {
    const [currentPage, setCurrentPage] = useState<number>(1)

    const { getRequest: getInventoryItems, data: itemsData } = useFetch(
        `${baseUrl}/inventory/view?page=${currentPage}`
    )

    useEffect(() => {
        getInventoryItems()
    }, [getInventoryItems])
    let extractedData = []
    if (itemsData?.items) {
        extractedData = itemsData?.items?.map((item) => {
            return {
                IMEI: item?.imei,
                Date: moment(item?.createdAt).format('DD/MM/YYYY'),
                Model: item?.model?.modelName,
                Storage: item?.storage,
                'VAT type': item?.vat,
                Status: item?.event,
                Code: item?.codes.toString(),

            }
        })
    }

    useEffect(() => {
        setCurrentPage(itemsData?.pagination?.pageCount)
        // setSortedData(itemsData?.items);
        // setModels(modelsData);
        // const myCodes = codesData?.map((element) => {
        //   return { label: element?.label, value: element?.description };
        // });
        // setCodes(myCodes);
    }, [itemsData?.pagination?.pageCount])
    const columns = [
        'IMEI',
        'Date',
        'Model',
        'Storage',
        'VAT type',
        'Status',
        'Code',
    ]

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className='w-full'>
                    <Table
                        options={['Edit', 'Delete', 'Download PDF']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                    />
                </PrimaryCard>
            </Flex>
        </>
    )
}
